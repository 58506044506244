<template>
  <div class="view">
    <h2 class="title">命令行助手</h2>
  </div>
</template>

<script>
export default {
  name: "CLIHelper",
  data() {
    return {
      inputText: "",
    };
  },

  methods: {},
};
</script>

<style scoped>
</style>
